<template>
  <div class="add-currency">
    <n-button class="add-btn" icon="plus-circle" flat round @click="$var('add-currency', true)" />
    <div class="modal">
      <n-modal v-if="$var('add-currency')" :loading="$var('load')" @close="$var('add-currency', false)">
        <n-form @submit="submit">
          <div class="form">
            <n-items>
              <h3>Добавить Курс</h3>
              <n-input title="Валюта" v-bind="$form.input('title')" />
              <n-input title="Курс" v-bind="$form.input('value')" />
              <div class="form-buttons">
                <n-button @click="() => $var('add-currency', false)">Отменить</n-button>
                <n-button type="submit" color="success">Добавить</n-button>
              </div>
            </n-items>
          </div>
        </n-form>
      </n-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddCurrency',
  data: () => ({

  }),
  mounted() {
    this.$form.init({
      title: '',
      value: '',
    })
  },
  methods: {
    submit() {
      this.$var('load', true)
      const data = {
        ...this.$form.get(),
      }
      $api.app.currencies.create(data).then(() => {
        this.$var('add-currency', false)
        this.$emit('load')
      }).finally(() => {
        this.$form.init({
          title: '',
          value: '',
        })
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.add-currency {
  .add-btn {
    margin-left: 10px;
  }
  .modal {
    .form {
      text-align: center;
      .form-buttons {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
</style>
