<template>
  <div class="page-settings">
    <Currencies />
    <CategoriesTable />
  </div>
</template>

<script>
import CategoriesTable from './components/categoriesTable'
import Currencies from './Currencies/Index'

export default {
  name: 'PageSettings',
  components: {
    CategoriesTable,
    Currencies,
  },
}
</script>

<style scoped lang="scss">
.page-settings {

}
</style>
