<template>
  <div class="currencies">
    <n-card :loading="$var('load')">
      <div class="container">
        <div class="title">
          <h3>Курс валют</h3>
          <Add @load="loadCurrencies" />
        </div>
        <div class="table">
          <table>
            <tr class="table-headers">
              <td>Валюта</td>
              <td>Курс</td>
              <td></td>
            </tr>
            <tr v-for="item in data" :key="item.id">
              <td>{{ item.title }}</td>
              <td>{{ item.value }}</td> 
              <td class="tools">
                <n-button class="delete-btn" round icon="trash" @click="deleteCurrency(item.id)"></n-button>
                <n-button round icon="edit" @click="editCurrency(item.id)"></n-button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="modal">
        <n-modal v-if="$var('currency-modal')" :loading="$var('modal-load')" @close="$var('currency-modal', false)">
          <n-form @submit="submit">
            <div class="form">
              <n-items>
                <h3>Редактирование курса валют</h3>
                <n-input title="Валюта" v-bind="$form.input('title')" />
                <n-input title="Курс" v-bind="$form.input('value')" />
                <div class="form-buttons">
                  <n-button @click="() => $var('currency-modal', false)">Отменить</n-button>
                  <n-button type="submit" color="success">Сохранить</n-button>
                </div>
              </n-items>
            </div>
          </n-form>
        </n-modal>
      </div>
    </n-card>
  </div>
</template>

<script>
import Add from './components/AddCurrency'

export default {
  name: 'Index',
  components: {
    Add,
  },
  data: () => ({
    data: [],
  }),
  mounted() {
    this.loadCurrencies()
    this.$form.init({
      title: '',
      value: '',
    })
    this.$form.rules({
      title: [ 'required', ],
      value: [ 'required', ],
    })
  },
  methods: {
    loadCurrencies() {
      this.$var('load', true)
      $api.app.currencies.get().all().then((result) => {
        this.data = result.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
    editCurrency(v) {
      this.$var('modal-load', true)
      this.$var('currency-modal', true)
      $api.app.currencies.get(v).then((result) => {
        this.$form.init(result.data.content)
        this.$var('modal-load', false)
      })
    },
    submit() {
      const data = {
        ...this.$form.get(),
      }
      if (this.$form.check()) {
        this.$var('modal-load', true)
        $api.app.currencies.edit(data.id, data).then(() => {
          this.loadCurrencies()
          this.$var('currency-modal', false)
        }).finally(() => {
          this.$var('modal-load', false)
        })
      }
    },
    deleteCurrency(v) {
      this.$var('load', true)
      $api.app.currencies.delete(v).then(() => {
        this.loadCurrencies()
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.currencies {
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .modal {
    .form {
      text-align: center;
      .form-buttons {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .container {
    table {
      width: 100%;
      .table-headers {
        background: #ECEFF1;
        color: #999;
        font-size: .9em;
        font-weight: 300;
        white-space: nowrap;
      }
      .tools {
        display: flex;
        justify-content: end;
        .delete-btn {
          margin-right: 20px;
        }
      }
    }
    &:last-child tr {
      width: 20%;
    }
    td {
      padding: 10px 15px;
      border-bottom: 2px solid #f6f6f6;
    }
  }
}
</style>
