<template>
  <div class="add-category">
    <n-button @click="() => $var('create', true)" icon="plus-circle" round flat></n-button>
    <n-modal :loading="$var('load')" v-if="$var('create')" @close="$var('create', false)">
      <n-form @submit="submit">
        <div class="form">
          <n-items>
            <h3>Создание категории</h3>
            <n-input title="Название категории" v-bind="$form.input('title')" />
            <div class="form-buttons">
              <n-button @click="() => $var('create', false)">Отменить</n-button>
              <n-button type="submit" color="success">Добавить</n-button>
            </div>
          </n-items>
        </div>
      </n-form>
    </n-modal>
  </div>
</template>

<script>
export default {
  name: 'AddCategory',
  mounted() {
    this.$form.init({
      title: '',
    })
    this.$form.rules({
      title: ['required'],
    })
  },
  methods: {
    submit() {
      const data = {
        ...this.$form.get(),
      }
      if(this.$form.check()) {
        this.$var('load', true)
        $api.app.categories.create(data).then(() => {
          this.$emit('loadTable')
          this.$form.init()
          this.$var('create', false)
        }).finally(() => {
          this.$var('load', false)
        })
      }
    },
  },

}
</script>

<style lang="scss" scoped>
  .add-category {
    margin-left: 10px;
    .form {
      & h3 {
        text-align: center;
      }
      .form-buttons {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
</style>
