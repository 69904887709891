<template>
  <div class="categories-table">
    <n-card :loading="$var('loadTable')">
      <div class="add-button">
        <div>
          <h3>Категории</h3>
        </div>
        <div>
          <AddCategory @loadTable="loadTable" />
        </div>
      </div>
      <div class="table">
        <table>
          <tr class="table-headers">
            <td>Название категории</td>
            <td></td>
          </tr>
          <tr v-for="item in data" :key="item.id">
            <td>{{ item.title }}</td>
            <td class="tools">
              <n-button class="delete-btn" icon="trash" round @click="deleteCategory(item.id)" />
              <n-button icon="edit" round @click="editCategory(item.id)" />
            </td>
          </tr>
        </table>

      </div>
    </n-card>
<!--    <n-table :columns="columns" :data="data" :loading="$var('loadTable')">-->
<!--      <template #createdAt="{item}">-->
<!--        {{ $app.date.format(item.createdAt) }}-->
<!--      </template>-->
<!--      <template #tools1="{item}">-->
<!--        <n-button icon="trash" flat round @click="deleteCategory(item.id)" />-->
<!--      </template>-->
<!--      <template #tools2="{item}">-->
<!--        <n-button icon="edit" flat round @click="editCategory(item.id)" />-->
<!--        <n-modal :loading="$var('modal-load')" v-if="$var('edit')" @close="$var('edit', false)">-->
<!--          <n-form @submit="submit(item.id)">-->
<!--            <div class="form">-->
<!--              <n-items>-->
<!--                <h3>Редактирование категории</h3>-->
<!--                <n-input title="Название категории" v-bind="$form.input('title')" />-->
<!--                <div class="form-buttons">-->
<!--                  <n-button @click="() => $var('edit', false)">Отменить</n-button>-->
<!--                  <n-button type="submit" color="success">Сохранить</n-button>-->
<!--                </div>-->
<!--              </n-items>-->
<!--            </div>-->
<!--          </n-form>-->
<!--        </n-modal>-->
<!--      </template>-->
<!--    </n-table>-->
    <n-modal :loading="$var('modal-load')" v-if="$var('edit')" @close="$var('edit', false)">
      <n-form @submit="submit">
        <div class="form">
          <n-items>
            <h3>Редактирование категории</h3>
            <n-input title="Название категории" v-bind="$form.input('title')" />
            <div class="form-buttons">
              <n-button @click="() => $var('edit', false)">Отменить</n-button>
              <n-button type="submit" color="success">Сохранить</n-button>
            </div>
          </n-items>
        </div>
      </n-form>
    </n-modal>
  </div>
</template>

<script>
import AddCategory from './addCategory'
export default {
  name: 'CategoriesTable',
  components: {
    AddCategory,
  },
  data: () => ({
    data: [],
    columns: [
      { name: 'title', title: 'Название Категории', },
      { name: 'tools1', title: '', sort: 0, },
      { name: 'tools2', title: '', sort: 0, },
    ],
    categoryId: 0,
  }),
  mounted() {
    this.loadTable()
  },
  methods: {
    loadTable() {
      this.$var('loadTable', true)
      $api.app.categories.get().all().then((response) => {
        this.data = response.data.content
      }).finally(() => {
        this.$var('loadTable', false)
      })
    },
    editCategory(id) {
      this.$var('modal-load', true)
      this.$var('edit', true)
      this.categoryId = id
      $api.app.categories.get(id).then((response) =>{
        const data = response.data.content
        this.$form.init({
          title: data.title,
        })
        this.$var('modal-load', false)
      })
    },
    submit() {
      const data = {
        ...this.$form.get()
      }
      $api.app.categories.edit(this.categoryId, data).then(() => {
        this.loadTable()
      }).finally(() => {
        this.$var('edit', false)
      })
    },
    deleteCategory(id) {
      $api.app.categories.delete(id).then((response) => {
        this.$form.init(response, false)
        this.loadTable()
      }).finally(() => {
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .categories-table {
    table {
      width: 100%;
      .table-headers {
        background: #ECEFF1;
        color: #999;
        font-size: .9em;
        font-weight: 300;
        white-space: nowrap;
      }
      .tools {
        display: flex;
        justify-content: end;
        .delete-btn {
          margin-right: 20px;
        }
      }
    }
    td {
      padding: 10px 15px;
      border-bottom: 2px solid #f6f6f6;
    }
    .add-button {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
    }
    .form {
      & h3 {
        text-align: center;
      }
      .form-buttons {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
    }
  }
</style>
